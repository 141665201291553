.terms .wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  height: 100vh;
  padding: 2rem;
  padding-top: 5em;
  /* background-color: #eee; */
}
.terms .container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 1rem;
  background-color: var(--boxColor);
  width: 768px;
  height: 100%;
  border-radius: 0.25rem;
  box-shadow: 0rem 1rem 2rem -0.25rem rgba(0, 0, 0, 0.25);
}
.terms .container__heading {
  padding: 1rem 0;
  border-bottom: 1px solid #ccc;
  text-align: center;
}
.terms .container__heading > h2 {
  font-size: 1.75rem;
  line-height: 1.75rem;
  margin: 0;
  color: var(--primaryColor);
}
.terms .container__content {
  flex-grow: 1;
  overflow-y: scroll;
  text-align: justify;
}
.terms .container__content p{
  padding-right: 1em;
}
.terms .container__content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: var(--boxColor);
  border-radius: 10px;
}

.terms .container__content::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

.terms .container__content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(var(--brand-color)),
    to(var(--brand-color)),
    color-stop(0.6, #54de5d)
  );
}
.terms .container__nav {
  border-top: 1px solid #ccc;
  text-align: right;
  padding: 2rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.terms.pr .container__nav {
  display: block;
  text-align: center;
}
.terms .container__nav > small {
  color: #777;
  margin-right: 1rem;
}
.terms.pr .container__nav > small {
  color: #777;
  margin-right: 0rem;
  text-align: center;
}
@media (max-width: 978px) {
  .terms .container__nav {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .terms .container__nav > small {
    margin: 0;
    margin-bottom: 1em;
  }
}
@media (max-width: 745px) {
  .terms .wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    height: 100vh;
    padding: 2rem;
    padding-top: 7em;
    /* background-color: #eee; */
  }
}
