.aboutUs .we-deals {
  flex-direction: row-reverse;
}
.aboutUs main.left-right .rht-home {
  width: 55%;
  /* padding: 0 2em; */
  padding-left: 2em;
}
.aboutUs main.left-right .ltl-home {
  padding-right: 3em;
  width: 45%;
}

/* for aspects */
.aboutUs main.left-right.we-deals .rht-home {
  width: 45%;
  /* padding: 0 2em; */
  padding-left: 0em;
  padding-right: 2em;
}
.aboutUs main.left-right.we-deals .ltl-home {
  padding-right: 0em;
  padding-left: 3em;
  width: 55%;
}

/* we deal card */
.wedeal-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wedeal-card {
  display: flex;
  width: calc(50% - 0.25em);
  margin-bottom: 0.5em;
  align-items: center;
}
.wedeal-card svg {
  width: 35px;
  fill: #f9f9f9;
}
.wedeal-card svg * {
  fill: var(--brand-color);
}

.wedeal-card h5 {
  text-transform: capitalize;
  font-size: 18px !important;
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.wedeal-card:hover h5 {
  color: #f9f9f9 !important;
}
.wedeal-card:hover svg * {
  fill: #f9f9f9 !important;
}

/* increament */
.increamentContainer {
  margin: 1em 0;
}
.increamentContainer {
  transition: opacity 1.5s ease-in-out var(--delay),
    transform 0.7s ease-in-out var(--delay) !important;
}
.increamentContainer .meterContainer {
  background: var(--boxColor);
  height: 0.3em;
  border-radius: 5px;
}
.increamentContainer .info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3em;
  color: var(--primaryColor);
}
.increamentContainer .info h6 {
  font-size: 15px;
  font-weight: 500;
}
.increamentContainer .info h5 {
  font-size: 16px;
  font-weight: 500;
}
.increamentContainer .meterContainer .meter {
  background: var(--brand-color);
  height: 0.3em;
  border-radius: 5px;
  /* position: absolute; */
  /* width: 70%; */
}
.aboutUs .imageToLeft {
  transform: translate3d(-50%, 0, 0);
  opacity: 0;
  transition: opacity 0.8s ease-in-out, transform 0.6s ease-in-out;
}
.aboutUs .imageToRight {
  transform: translate3d(50%, 0, 0);
  opacity: 0;
  transition: opacity 0.8s ease-in-out, transform 0.6s ease-in-out;
}
.aboutUs .imageInview {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

@media (max-width: 1050px) {
  .aboutUs main.left-right .rht-home {
    width: 50%;
    /* padding: 0 2em; */
    padding-left: 2em;
  }
  .aboutUs main.left-right .ltl-home {
    padding-right: 3em;
    width: 50%;
  }
  .aboutUs main.left-right .ltl-home h1 {
    font-size: 35px !important;
    color: var(--primaryColor);
  }

  /* for aspects */
  .aboutUs main.left-right.we-deals .rht-home {
    width: 50%;
    /* padding: 0 2em; */
    padding-left: 0em;
    padding-right: 2em;
  }
  .aboutUs main.left-right.we-deals .ltl-home {
    padding-right: 0em;
    padding-left: 3em;
    width: 50%;
  }
  .aboutUs main.left-right.we-deals .ltl-home h1 {
    font-size: 31px !important;
    color: var(--primaryColor);
  }
}
@media (max-width: 978px) {
  .aboutUs main.left-right {
    display: flex;
    padding: 0 var(--main-margin);
    padding-top: 100px;
    flex-direction: column;
  }
  .aboutUs main.left-right .rht-home {
    width: 80%;
    /* padding: 0 2em; */
    padding-left: 2em;
  }
  .aboutUs main.left-right .ltl-home {
    padding-right: 3em;
    width: 100%;
    /* height: calc(100vh - 50px + 16px); */
  }
  .aboutUs main.left-right .ltl-home h1 {
    font-size: 55px;
    color: var(--primaryColor);
    margin-bottom: 0.5em;
  }
  /* for aspects */
  .aboutUs main.left-right.we-deals {
    flex-direction: column-reverse;
    align-items: center;
  }
  .aboutUs main.left-right.we-deals .rht-home {
    width: 65%;
    /* padding: 0 2em; */
    padding-left: 0em;
    padding-right: 0em;
  }
  .aboutUs main.left-right.we-deals .ltl-home {
    padding-right: 0em;
    padding-left: 0em;
    width: 100%;
  }
}
@media (max-width: 600px) {
  .aboutUs main.left-right .ltl-home h1 {
    font-size: 45px;
    color: var(--primaryColor);
    margin-bottom: 0.5em;
  }
}
@media (max-width: 650px) {
  .wedeal-card {
    width: 100%;
  }
}
.aboutUs .titleNotSeen {
  transform: translate3d(0%, 100%, 0);
  opacity: 0;
  transition: opacity 1.5s ease-in-out, transform 0.7s ease-in-out;
}
.aboutUs .titleInView {
  transform: translate3d(0%, -0%, 0);
  opacity: 1;
}
