.project .rht-home .box img {
  width: 45px;
  margin: 0 1em;
}
.project .rht-home .box .info {
  /* width: 45px; */

  margin: 0 1em;
}
.project .rht-home .box .info h3 {
  color: var(--primaryColor);
  margin: 0;
  font-size: 18px;
}
.project .rht-home .box {
  display: flex;
  /* background: red; */
  height: max-content;
  width: max-content;
  margin-top: 0.5em;
  width: 100%;
}
.project .rht-home .btm-info {
  margin-top: 1em;
  /* margin-bottom: 0.2em; */
}
.project .rht-home .btm-info h2 {
  /* margin-top: 1em; */
  margin-bottom: 0.2em;
}

.project .ltl-home .headerImage img {
  width: 100%;
}
.project .ltl-home img {
  width: 100%;
}

.project main.left-right .rht-home {
  width: 30%;
  /* padding: 0 2em; */
  padding-left: 2em;
}
.project main.left-right .ltl-home {
  padding-right: 3em;
  width: 70%;
}

@media (max-width: 978px) {
  .project main.left-right {
    display: flex;
    padding: 0 var(--main-margin);
    padding-top: 100px;
    flex-direction: column;
  }
  .project main.left-right .rht-home {
    width: 100%;
    /* padding: 0 2em; */
    padding-left: 0em;
  }
  .project main.left-right .ltl-home {
    padding-right: 0em;
    width: 100%;
    /* height: calc(100vh - 50px + 16px); */
  }
  .project main.left-right .ltl-home h1 {
    font-size: 55px;
    color: var(--primaryColor);
    margin-bottom: 0.5em;
  }
  .project .rht-home .box img {
    width: 45px;
    margin: 0 1em;
    margin-right: 4em;
  }
  .project .rht-home .box {
    display: flex;
    /* background: red; */
    height: max-content;
    width: 100%;
    margin-top: 0.5em;
  }
}
.project .toLeft {
  transform: translate3d(-50%, 0, 0);
  /* opacity: 0; */
  transition: transform 0.7s, opacity 1s;
}
.project .toRight {
  opacity: 0;
  transform: translate3d(50%, 0, 0);
  transition: transform 0.7s, opacity 1s;
}
.project .toRight:nth-child(1) {
  transition-delay: 0s;
}
.project .toRight:nth-child(2) {
  transition-delay: 100ms;
}
.project .toRight:nth-child(3) {
  transition-delay: 200ms;
}
.project .toRight:nth-child(4) {
  transition-delay: 300ms;
}

.project .sideBack {
  opacity: 1 !important;
  transform: translate3d(0, 0, 0) !important;
}

.project .pr-tm {
  display: flex;
  margin-top: 1em;
}
.project .pr-tm p {
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.project .pr-tm p:hover {
  color: var(--brand-color);
}
.project .pr-tm p:nth-child(1) {
  margin-right: 2em;
}
