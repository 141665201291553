/* FAQComponent.css */
.faq {
  margin: 6em var(--main-margin);
  /* transition: all 0.5s ease-in-out; */
}

.faq-item {
  margin: 0; /* Add this line to reset margin */
  overflow: hidden;
  transition: height 0.3s ease-in-out, padding 0.3s ease-in-out;
  margin-bottom: 1.5em;
}
.faq-item h4 {
  color: var(--title-2-color) !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.faq-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px; */
  margin: 0; /* Add this line to reset margin */
  cursor: pointer;
  /* background-color: #f5f5f5; */
  /* padding-bottom: 0.5em; */
}

.chevron-icon {
  width: 1.2rem;
  margin-left: 8px;
}

.faq-description {
  /* padding: 10px; */
  /* background-color: #fff; */
  /* margin: -10px; Add this line to reset margin */
  height: 0; /* Set the initial height to 0 */
  overflow: hidden; /* Hide the overflow content */
  transition: height 0.5s;
}
.faq-description p {
  line-height: 22px !important;
  font-size: 16px !important;
  margin-bottom: 0;
}

/* to change left to right */
.faq main.left-right {
  flex-direction: row-reverse;
  padding: 0;
}
.faq main.left-right .rht-home {
  width: 50%;
  /* padding: 0 2em; */
  padding-left: 0em;
  padding-right: 2em;
}
.faq main.left-right .ltl-home {
  padding-right: 0em;
  padding-left: 3em;
  width: 50%;
}
@media (max-width: 1050px) {
  /* to change left to right */
  .faq main.left-right .rht-home {
    width: 50%;
    /* padding: 0 2em; */
    padding-left: 0em;
    padding-right: 2em;
  }
  .faq main.left-right .ltl-home {
    padding-right: 0em;
    padding-left: 3em;
    width: 50%;
  }
}
@media (max-width: 978px) {
  /* to change left to right */
  .faq main.left-right {
    flex-direction: column-reverse;
    align-items: center;
  }
  .faq main.left-right .rht-home {
    width: 65%;
    /* padding: 0 2em; */
    padding-left: 0em;
    padding-right: 0em;
  }
  .faq main.left-right .ltl-home {
    padding-right: 0em;
    padding-left: 0em;
    width: 100%;
  }
}
.faq .imageOff {
  transform: translate3d(-50%, 0, 0);
  opacity: 0;
  transition: opacity 1s, transform 0.7s;
}
.faq .imageOn {
  transform: translate3d(0%, 0, 0);
  opacity: 1;
  /* transition: opacity 1s, transform 0.7s; */
}
.faq .faq-item {
  transform: translate3d(0%, 100%, 0);
  opacity: 0;
  transition: opacity 1s, transform 0.7s;
}
.faq .faqIn .faq-item {
  transform: translate3d(0%, 0%, 0);
  opacity: 1;
}
