.testimonials {
  margin: 6em var(--main-margin);
}
/* .testimonials .testimonialContainer .singleTestimonial {
  width: calc(33.3333% - 1em);
  min-width: calc(33.3333% - 1em);
  margin-right: 1em;
} */
.testimonials .testimonialContainer .singleTestimonial .heading .profileImg {
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
  border-radius: 100%;
}
.testimonials
  .testimonialContainer
  .singleTestimonial
  .heading
  .profileImg
  img {
  height: 100%;
}
.testimonials .testimonialContainer .singleTestimonial .heading {
  display: flex;
  align-items: center;
}
.testimonials .testimonialContainer .singleTestimonial .heading .nameProf {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
}
.testimonials .testimonialContainer .singleTestimonial .heading .nameProf p {
  font-size: 14px !important;
  font-weight: 300;
  opacity: 0.8;
  margin: 0;
  line-height: normal;
}
.testimonials .testimonialContainer .singleTestimonial .heading .nameProf h1 {
  font-size: 18px !important;
  margin: 0;
  padding: 0;
}
/* 
.testimonial-slider .slick-slide {
  margin: 0 10px; 

} */
.testimonials .testimonialContainer .containSlider {
  padding: 0.5em;
}
.testimonials .testimonialContainer .slick-track {
  /* padding: 0.5em; */
  transition: 0s;
}

.testimonial-slider .slick-dots li button:before {
  color: var(--primaryColor); /* Replace with your default color value */
  font-size: 11px; /* Replace with your default size value */
}

.testimonial-slider .slick-dots li.slick-active button:before {
  color: var(--brand-color); /* Replace with your active color value */
  font-size: 14px; /* Replace with your active size value */
}

.testimonial-slider .slick-dots li button:hover:before,
.testimonial-slider .slick-dots li button:focus:before {
  color: var(--brand-color); /* Replace with your hover color value */
  font-size: 14px; /* Replace with your hover/focus size value */
}

.testimonial-slider .slick-prev:before,
.testimonial-slider .slick-next:before {
  color: var(--primaryColor); /* Replace with your desired arrow color */
}

/* .testimonial-slider .slick-prev:focus:before, */
/* .testimonial-slider .slick-next:focus:before  */
.testimonial-slider .slick-prev:hover:before,
.testimonial-slider .slick-next:hover:before {
  color: var(--brand-color); /* Replace with your desired hover color */
}
.testimonials .titleNotSeen {
  transform: translate3d(0%, 100%, 0);
  opacity: 0;
  transition: opacity 1.5s ease-in-out, transform 0.7s ease-in-out;
}
.testimonials .titleInView {
  transform: translate3d(0%, -0%, 0);
  opacity: 1;
}
