.gallery {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
}
.gallery .image {
  width: calc((100% / 3) - 2em);
  height: 250px;
  /* aspect-ratio: 1 / 1; */
  margin: 1em;
  overflow: hidden;
  /* border: 0.1em solid var(--brand-color); */
  border-radius: 10px;
  transform: scale(0.8) rotate(-10deg);
  /* transition: /; */
  transition: transform 0.3s ease,
    /* Transition for scale property */ rotate 0.5s ease;
  user-select: none;
  cursor: pointer;
}

.gallery .image::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, black);
  z-index: 2;
  opacity: 1;
  transition: opacity 0.5s 0.3s, visibility 0.5s;
}
.gallery .image:hover:before {
  opacity: 0;
  visibility: hidden;
  /* background: linear-gradient(transparent, transparent); */
}
/* @keyframes hoverImage {
  0% {
    transform: scale(0.8) rotate(-10deg);
  }
  50% {
    transform: scale(0.8) rotate(0);
  }
  100% {
    transform: scale(1) rotate(0);
  }
} */
.gallery .image:hover {
  /* animation: 1s ease-in-out forwards hoverImage; */
  transform: scale(1) rotate(0);
  z-index: 3;
}

.gallery .image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* or contain, fill, etc. based on your requirements */
  display: block;
  filter: grayscale(100%);
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  transition: 0.5s;
}
.loading-shimmer {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.gallery .image:hover img {
  filter: grayscale(0);
}
.gallery .fullImage {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background: rgba(0, 0, 0, 0.755);
  z-index: 9999;
  visibility: hidden;
  transition: visibility 0s;
  position: fixed;
}
.gallery .fullImage img {
  user-select: none;
  max-width: 100%;
  /* min-width: 300px; */
  max-height: 100%;
  opacity: 0;
  transform: scale(0.8) rotate(-45deg);
  transition: opacity 0.5s, transform 0.5s 0.1s, border-radius 0.5s 0.5s;
  background: var(--bodyColor);
}
.gallery .fullImage.visible {
  visibility: visible;
}
.gallery .fullImage.visible img {
  visibility: visible;
  opacity: 1;
  transform: scale(1) rotate(0deg);
  border-radius: 10px;
}
.gallery .fullImage.visible img.close {
  /* visibility: hidden; */
  opacity: 0;
  transform: scale(0) rotate(-45deg);
  border-radius: 0px;
  transition: opacity 0.5s 0.7s, transform 0.5s 0.2s, border-radius 0.2s;
}

.gallery .fullImage > .cancel {
  font-size: 1.1em;
  background: var(--error-color);
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;
  margin: 1em;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  padding: 0.5em;
  color: #ebebeb;
  cursor: pointer;
  transition: all 0s;
}
@media (max-width: 990px) {
  .gallery .image {
    width: calc((100% / 2) - 2em);
  }
}
@media (max-width: 600px) {
  .gallery .image {
    width: calc((100% / 1) - 2em);
  }
}
