/* VisitSiteButton.css */

.visit-site-link {
  text-decoration: none;
}

.visit-site-button {
  padding: 15px 30px;
  background-color: #1fa84f;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
  /* Ensure overflow is hidden for the pseudo-elements */
}

.visit-site-button:hover {
  background-color: #147a3e;
}
