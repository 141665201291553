.home {
  font-family: headFont;
}

.home main.left-right .rht-home {
  width: 50%;
  /* padding: 0 2em; */
  padding-left: 2em;
  /* overflow: hidden; */
}
.home main.left-right .rht-home img.isNotVisibleIm {
  /* visibility: none; */
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 2s, transform 1.5s ease-in-out;
}
.home main.left-right .ltl-home {
  padding-right: 3em;
  width: 35%;
}
.home main.left-right .ltl-home .isNotVisible {
  transform: translateY(100%);
  opacity: 0;
  transition: opacity 1.5s ease-in-out var(--delay),
    transform 1s ease-in-out var(--delay);
}
.homeEmisVisible {
  opacity: 1 !important;
  transform: translateY(0) !important;
}
@media (max-width: 1200px) {
  .home main.left-right .rht-home {
    width: 60%;
    /* padding: 0 2em; */
    padding-left: 2em;
  }
  .home main.left-right .ltl-home {
    padding-right: 3em;
    width: 40%;
  }
}
@media (max-width: 978px) {
  .home main.left-right {
    display: flex;
    padding: 0 var(--main-margin);
    padding-top: 100px;
    flex-direction: column;
  }
  .home main.left-right .rht-home {
    width: 100%;
    /* padding: 0 2em; */
    padding-left: 2em;
  }
  .home main.left-right .ltl-home {
    padding-right: 3em;
    width: 100%;
    /* height: calc(100vh - 50px + 16px); */
  }
  .home main.left-right .ltl-home h1 {
    font-size: 55px;
    color: var(--primaryColor);
    margin-bottom: 0.5em;
  }
}
