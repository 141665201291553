.clients {
  margin: 7em 0;
}

.clients .clientsHeader {
  padding: 60px 0;
  background-color: var(--dark-brand-color);
  color: #f9f9f9;
  text-align: center;
  display: flex;
  height: max-content;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 3.5em;
}

.clients .clientsHeader .counter {
  font-size: 50px;
  font-weight: bold;
  color: var(--brand-color);
}

.clients .clientsHeader .title-2 {
  color: #f9f9f9 !important;
}
.clients .content {
  margin: var(--main-margin);
}
.clients .content .whychooseusContainers {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: ; */
  /* align-items: flex-start; */
}
.clients .content .whychooseusContainers > div {
  width: calc(33.3333333% - 1em);
  margin: 0.5em;
}
.clients .content .whychooseusContainers .box h1 {
  font-size: 50px;
  color: var(--brand-color);
  font-weight: bold;
}
.clients .content .whychooseusContainers .box h4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

@media (max-width: 980px) {
  .clients .clientsHeader {
    display: block;
  }
  .clients .content .whychooseusContainers > div {
    width: calc(50% - 1em);
    margin: 0.5em;
  }
}
@media (max-width: 780px) {
  .clients .content .whychooseusContainers > div {
    width: calc(100vh - 1em);
    margin: 0.5em;
  }
}
.clients .clientsHeader > div:nth-child(1) .info.clientHeadNotSeen {
  transform: translate3d(-80%, 0, 0);
  opacity: 0;
  transition: opacity 0.8s ease-in-out, transform 0.6s ease-in-out;
}
.clients .clientsHeader > div:nth-child(2) .info.clientHeadNotSeen {
  transform: translate3d(0%, 80%, 0);
  opacity: 0;
  transition: opacity 0.8s ease-in-out, transform 0.6s ease-in-out;
}
.clients .clientsHeader > div:nth-child(3) .info.clientHeadNotSeen {
  transform: translate3d(0%, -80%, 0);
  opacity: 0;
  transition: opacity 0.8s ease-in-out, transform 0.6s ease-in-out;
}
.clients .clientsHeader > div:nth-child(4) .info.clientHeadNotSeen {
  transform: translate3d(80%, 0%, 0);
  opacity: 0;
  transition: opacity 0.8s ease-in-out, transform 0.6s ease-in-out;
}
.clients .clientsHeader .clientHeadInView {
  transform: translate3d(0%, 0%, 0) !important;
  opacity: 1 !important;
}
.clients .titleNotSeen {
  transform: translate3d(0%, 100%, 0);
  opacity: 0;
  transition: opacity 1.5s ease-in-out, transform 0.7s ease-in-out;
}
.clients .titleInView {
  transform: translate3d(0%, -0%, 0);
  opacity: 1;
}

.clients .boxToLeft {
  transform: translate3d(-50%, 0, 0);
  opacity: 0;
  transition: opacity 1.5s ease-in-out, transform 0.7s ease-in-out;
}
.clients .boxToRight {
  transform: translate3d(50%, 0, 0);
  opacity: 0;
  transition: opacity 1.5s ease-in-out, transform 0.7s ease-in-out;
}
.clients .boxToTop {
  transform: translate3d(0, -50%, 0);
  opacity: 0;
  transition: opacity 1.5s ease-in-out, transform 0.7s ease-in-out;
}
.clients .boxInView {
  transform: translate3d(0%, 0%, 0);
  opacity: 1;
}
