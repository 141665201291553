.otherPageBanner {
  background: var(--dark-brand-color);
  min-height: 40px;
  margin-top: 82px;
  color: var(--bodyColor);
  padding: 24px calc(12px + 37px);
}

.otherPageBanner h1 {
  color: #eaf0ec !important;
}

.otherPageBanner p {
  color: #b6b6b6 !important;
}
.otherPageBanner ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
  text-transform: uppercase;
}
.otherPageBanner ol a {
  color: #f8f6f4;
  transition: 0.3s;
}
.otherPageBanner ol li + li {
  padding-left: 10px;
  color: #f9f9f9;
}
.otherPageBanner ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #f8f6f4;
  content: "/";
}
