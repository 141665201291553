.portfolio {
  margin: 6em var(--main-margin);
}
.portfolio > .title {
  margin: 0.2em 0;
  margin-bottom: 0.45em;
}

.portfolio .projects .navProjects ul {
  display: flex;
}
.portfolio .projects .navProjects ul li {
  margin-right: 2em;
}
.portfolio .projectsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.portfolio .projectsContainer .singleProject {
  /* width: calc(33.33333% - 1em);
  margin: 0.5em;
  margin-left: 0;
  margin-right: 1em;
  aspect-ratio: 1/1; */
  width: 100%;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  aspect-ratio: 1/1;
  cursor: pointer;
}
.portfolio .projectsContainer > div {
  width: calc(33.33333% - 1em);
  margin: 0.5em;
  margin-left: 0;
  margin-right: 1em;
  /* border-radius: 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer; */
}
.portfolio .projectsContainer .singleProject:hover {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.portfolio .projectsContainer .singleProject img.back-Img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* or contain, fill, etc. based on your requirements */
  display: block;
  z-index: 1;
  position: absolute;
}
.portfolio .projectsContainer .singleProject .veiwProjectOverly {
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.224); */
  transform: translateY(100%);
  position: absolute;
  z-index: 2;
  transition: 0.5s;
  /* background: linear-gradient(transparent, black); */
}
.portfolio .projectsContainer .singleProject .veiwProjectOverly-black {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  transition: 0.5s;
  background: linear-gradient(transparent, black);
  opacity: 0;
}
.portfolio .projectsContainer .singleProject:hover .veiwProjectOverly {
  transform: translateY(0%);
}
.portfolio .projectsContainer .singleProject:hover .veiwProjectOverly-black {
  background: linear-gradient(transparent, black);
  opacity: 1;
}
.portfolio .projectsContainer .singleProject .veiwProjectOverly {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.portfolio .projectsContainer .singleProject .veiwProjectOverly .viewButton {
  height: max-content;
  align-items: center;
  display: flex;
  padding: 7px 10px;
  color: var(--brand-color);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--boxColor);
  cursor: pointer;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  transform: translateY(100%);
  transition: 0.5s;
  transition-delay: 0.2s;
}
.portfolio
  .projectsContainer
  .singleProject:hover
  .veiwProjectOverly
  .viewButton {
  transform: translateY(0%);
}
.portfolio .projectsContainer .singleProject .veiwProjectOverly h3 {
  font-size: 25px;
  font-weight: bold;
  text-transform: uppercase;
  color: #f9f9f9;
  margin-bottom: 0.5rem;
  /* font-weight: 500; */
  line-height: 1.2;
}
.portfolio .projectsContainer .singleProject .veiwProjectOverly h6 {
  font-size: 20px;
  text-transform: capitalize;
  transition: 0.5s;
  transition-delay: 0.05s;
  transform: translateY(100%);
  color: #f9f9f9;
  margin-top: 0;
}
.portfolio .projectsContainer .singleProject:hover .veiwProjectOverly h6 {
  transform: translateY(0%);
}

.portfolio .projectsContainer .singleProject .veiwProjectOverly .viewButton a {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 500;
  margin: 0;
  transition: 0.5s;
}
.portfolio .projectsContainer .singleProject .veiwProjectOverly .madeWith {
  display: flex;
  justify-content: center;
  /* transition: 0s; */
  transition-delay: 0.15s;
  transform: translateY(100%);
}
.portfolio
  .projectsContainer
  .singleProject:hover
  .veiwProjectOverly
  .madeWith {
  transform: translateY(0%);
}
.portfolio .projectsContainer .singleProject .veiwProjectOverly .madeWith p {
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  color: #1fa84f;
  margin: 0 0.5rem;
}
.portfolio
  .projectsContainer
  .singleProject
  .veiwProjectOverly
  .viewButton
  img {
  width: 25px;
  height: 25px;
  /* display: block; */
  margin-right: 0.5em;
}
.box.h3-tsest {
  padding: 5em;
}

@media (max-width: 1000px) {
  .portfolio .projectsContainer > div {
    width: calc((100% / 2) - 1em);
    margin: 0.5em;
  }
}
@media (max-width: 750px) {
  .portfolio .projectsContainer > div {
    width: calc(100% - 1em);
    margin: 0.5em 3em;
  }
}
@media (max-width: 650px) {
  .portfolio .projectsContainer > div {
    width: calc(100% - 1em);
    margin: 0.5em;
  }
}

.portfolio .elementOut {
  transform: translate3d(0, 100%, 0);
  opacity: 0;
  transition: opacity 1.5s ease-in-out, transform 0.7s ease-in-out;
}
.portfolio .elementIn {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: opacity 1.5s ease-in-out, transform 0.7s ease-in-out;
}
