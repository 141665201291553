footer {
  background-color: #1c1d1c;
}
footer hr {
  color: #b1b1b1;
  border: none;
  background: #b1b1b1;
  height: 1px;
}
footer .top {
  display: flex;
  flex-wrap: wrap;
  margin: 0 var(--main-margin);
  align-items: center;
}
footer .bottom {
  display: flex;
  /* flex-wrap: wrap; */
  margin: 0 var(--main-margin);
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
}
footer .top > div {
  width: calc(100% / 4);
  /* to be removed */
  /* aspect-ratio: 1/1; */
  /* height: 244.52px; */
  /* background: red; */
  /* outline: 1px blue solid; */
  /* to ----- */
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
  /* padding: 0 calc(1.5rem * 0.5); */
}
footer .bottom a {
  color: var(--secendaryColor);
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #d8d8d8;
}
footer .bottom p {
  margin: 0;
}
footer .top div .box {
  background-color: var(--dark-brand-color);
  /* padding: 0.5em; */
}
footer .top div .box .logo img {
  width: 50%;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
footer .top div .box h4 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  color: #b6b6b6;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}
footer .top div .box .social {
  display: flex;
  /* justify-content: space-between; */
}
footer .top div .box .social .icon {
  color: #fff;
  background-color: var(--brand-color);
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-right: 0.5rem;
  /* aspect-ratio: 1/1; */
}
footer .top div .box .social .icon img {
  width: 16px;
}
footer .top > div.ref-links {
  display: flex;
  flex-direction: column;
  padding-left: calc(2em - 4px - 4px);
}
footer .top > div.ref-links h4 {
  color: #b6b6b6;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 0.4em;
}
footer .top > div.ref-links ul {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* height: 100%; */
}
footer .top > div.ref-links ul li {
  display: flex;
  margin-top: 0.5em;
  padding: 0.4em 0;
}
footer .top > div.ref-links ul li img {
  width: 15px;
  margin-right: 0.3em;
}
footer .top > div.ref-links ul li a {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  color: #ddd !important;
}
@media (max-width: 990px) {
  footer .top > div {
    width: calc(100% / 2);
  }
}
@media (max-width: 770px) {
  footer .top > div {
    width: calc(100% / 1);
  }
}
footer .footer_element_animated ul li {
  transform: translate3d(0, 100%, 0);
  opacity: 0;
  transition: transform 0.7s var(--delay), opacity 1s var(--delay);
}
footer .footer_element_animatedIn ul li {
  opacity: 1;
  transform: translate3d(0, 00%, 0);
  /* transition: transform 7s var(--delay), opacity 1s var(--delay); */
}
footer .box.footer_element_animated > a,
footer .box.footer_element_animated > p,
footer .box.footer_element_animated > h4,
footer .box.footer_element_animated .icon {
  transform: translate3d(0, 100%, 0);
  opacity: 0;
  transition: transform 0.7s var(--delay), opacity 1s var(--delay);
}
footer .box.footer_element_animatedIn > a,
footer .box.footer_element_animatedIn > p,
footer .box.footer_element_animatedIn > h4,
footer .box.footer_element_animatedIn .icon {
  opacity: 1;
  transform: translate3d(0, 00%, 0);
}
