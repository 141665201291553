.team {
  margin: 6em var(--main-margin);
}
.team .profileCardContainer {
  display: flex;
  flex-wrap: wrap;
}
.team .profileCardContainer .profileCard {
  padding: 0;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.team .profileCardContainer > div {
  width: calc(25% - 1.5em);
  margin: calc(1.5em / 2) 0;
  margin-right: 1.5em;
}
.team .profileCardContainer .profileCard h3 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.team .profileCardContainer .profileCard h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  opacity: 0.6;
  line-height: 1.2;
  color: var(--title-2-color);
  font-size: 16px;
}

.team .profileCardContainer .profileCard .proImg {
  width: 100%;
  aspect-ratio: 1/1;
  /* background: red; */
  position: relative;
}
.team .profileCardContainer .profileCard .proImg img {
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
  aspect-ratio: 1/1;
}
.team .profileCardContainer .profileCard .links {
  display: flex;
  justify-content: center;
}
.team .profileCardContainer .profileCard .links a svg {
  width: 24px;
  /* margin: calc(40px - 24px); */
  /* font-size: 1.5rem; */
  margin: 0 0.5rem;
  color: var(--secendaryColor);
}
.team .profileCardContainer .profileCard .links a svg * {
  fill: var(--secendaryColor);
  stroke: var(--secendaryColor);
}
.team .profileCardContainer .profileCard .links a svg:hover * {
  fill: var(--brand-color);
  stroke: var(--brand-color);
}
@media (max-width: 1100px) {
  .team .profileCardContainer > div {
    width: calc((100% / 3) - 1.5em);
    margin: calc(1.5em / 2) 0;
    margin-right: 1.5em;
  }
}
@media (max-width: 988px) {
  .team .profileCardContainer > div {
    width: calc((100% / 2) - 1em);
    margin: calc(1em / 2) 0;
    margin-right: 1em;
  }
}
@media (max-width: 688px) {
  .team .profileCardContainer > div {
    width: calc((90%) - 1.5em);
    margin: calc(1.5em / 2);
    margin-right: 0em;
  }
  .team .profileCardContainer {
    justify-content: center;
  }
}
.team .titleNotSeen {
  transform: translate3d(0%, 100%, 0);
  opacity: 0;
  transition: opacity 1.5s ease-in-out, transform 0.7s ease-in-out;
}
.team .titleInView {
  transform: translate3d(0%, -0%, 0);
  opacity: 1;
}
