.offer-card {
  width: calc(33.3333% - 1em);
  margin: 0.5em;
  border-radius: 10px;
  /* opacity: 0; */
  transition: color 0.7s ease-in-out;
}
.offer-card .box * {
  transition: color 0.7s ease-in-out;
}

.offer-card:nth-child(1) .box {
  transform: translate3d(-50%, 0, 0);
  opacity: 0;
  transition: opacity 1.5s ease-in-out, transform 1s ease-in-out;
}
.offer-card:nth-child(2) .box {
  transform: translate3d(0%, -50%, 0);
  opacity: 0;
  transition: opacity 1.5s ease-in-out, transform 1s ease-in-out;
}
.offer-card:nth-child(3) .box {
  transform: translate3d(50%, 0, 0);
  opacity: 0;
  transition: opacity 1.5s ease-in-out, transform 1s ease-in-out;
}
.offer-card:nth-child(4) .box {
  transform: translate3d(-50%, 0, 0);
  opacity: 0;
  transition: opacity 1.5s ease-in-out, transform 1s ease-in-out;
}

.offer-card svg {
  fill: var(--brand-color);
  width: 96px;
  transition: fill 0.7s ease-in-out !important;
}
.offer-card:hover .icon svg {
  fill: #f8f6f4;
}
.offers-ech {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}
.offers-ech.offersInView .offer-card .box {
  transform: translate3d(0, 0%, 0) !important;
  opacity: 1 !important;
}
.offers {
  margin: 4em var(--main-margin);
}
.offers .heading .titleNotInview {
  transform: translate3d(0, calc(100%), 0);
  opacity: 0;
  transition: opacity 0.8s ease-in-out var(--delay),
    transform 0.6s ease-in-out var(--delay);
}
.offers .heading .pretitle.titleNotInview {
  transform: translate3d(0, calc(150%), 0);
  opacity: 0;
}
.offers .offersInView {
  transform: translate3d(0, 0%, 0) !important;
  opacity: 1 !important;
}
.learn-more {
  text-transform: capitalize;
  color: var(--brand-color);
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
}
.offer-card .learn-more svg {
  width: 24px;
  /* stroke: var(--brand-color); */
  fill: var(--brand-color);
  margin-left: 0.2em;
}

.offer-card .learn-more svg path {
  fill: var(--brand-color);
  /* stroke: var(--brand-color); */
  transition: fill 0.7s ease-in-out !important;
}

.offer-card:hover .learn-more svg path {
  fill: #f8f6f4;
  /* stroke: #f8f6f4; */
}
@media (max-width: 850px) {
  .offer-card {
    width: calc(50% - 1em);
    margin: 0.5em;
    border-radius: 10px;
  }
}
@media (max-width: 650px) {
  .offer-card {
    width: calc(100%);
    margin: 0.5em;
    border-radius: 10px;
    margin: 0.5em 0;
  }
}
