/* AppDownloadPage.css */

.app-download-page {
  padding: 20px;
  background-color: var(--bodyColor);
  color: var(--dark-brand-color);
}

.version-list {
  margin-bottom: 20px;
}

.version-list h1 {
  /* color: var(--primaryColor); */
  margin: 0.5em 0;
}

.version-list ul {
  list-style: none;
  padding: 0;
  display: flex;
}

.version-list li {
  margin-right: 10px;
  cursor: pointer;
  color: var(--brand-color);
  text-decoration: none;
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid var(--brand-color);
  user-select: none;
}

.version-list li:hover {
  background-color: var(--brand-color);
  color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.version-list li.selected {
  background-color: var(--brand-color);
  color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.download-section {
  padding: 20px;
  border: 1px solid var(--boxColor);
}

.download-section h2,
.download-section p {
  color: var(--title-2-color);
}

.download-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  background-color: var(--brand-color);
  color: #ffffff;
}
.download-section.camIn {
  animation: forwards 1s camIn;
}
@keyframes camIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  60% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
