.nav {
  display: flex;
  justify-content: space-between;
  /* background: red; */
  position: relative;
  align-items: center;
  padding: 0.5em var(--main-margin);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 900;
  height: 70px;
  /* padding-top: 2em; */
}
.nav.pc {
  display: flex;
}
.nav.pc .menu-slide-btn {
  display: none;
}
.nav.mobile {
  display: none;
}

.nav ul {
  display: flex;
}
.nav ul li {
  font-family: mainFont;
  cursor: pointer;
}
.nav ul li a {
  font-weight: 600;
  text-transform: capitalize;
  color: var(--secendaryColor) !important;
  padding: 10px;
  transition: 0.25s ease-in-out color;
}
.nav ul li:hover a {
  color: var(--brand-color) !important;
}
.nav .themeChange img {
  width: 24px;
}

.nav .themeChange {
  background: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--boxColor);
  width: 45px;
  height: 45px;
  border: 0.5px solid var(--brand-color);
  cursor: pointer;
}
.nav .logo {
  padding: calc((60px - 45px) / 2);
}
.nav .logo > img {
  height: 45px;
}
#themeChange {
  display: none;
}

.nav.sticky {
  background-color: var(--boxColor);
  transition: 1s;
}
.menu-slide-btn {
  height: max-content;
  cursor: pointer;
}
.menu-slide-btn img {
  width: 35px;
  height: auto;
}

@media (max-width: 750px) {
  .logo {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .nav .logo > img {
    height: 45px;
  }
  .nav.pc {
    display: none;
  }
  .nav.mobile {
    display: flex;
    height: calc(60px + 16px);
    overflow: hidden;
    transition: 0.5s;
  }
  .nav.mobile.openMobileNav {
    height: 337px;
    height: calc(40px + 337px);
  }
  .nav {
    display: flex;
    justify-content: space-between;
    /* background: red; */
    position: relative;
    align-items: center;
    padding: 0.5em var(--main-margin);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 900;
    height: auto;
    /* padding-top: 2em; */
    background: var(--boxColor);
    flex-direction: column;
    align-items: start;
  }
  .nav ul {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .nav ul li {
    font-family: mainFont;
    cursor: pointer;
    width: 100%;
  }
  .nav ul li a {
    font-weight: 600;
    text-transform: capitalize;
    color: var(--secendaryColor) !important;
    padding: 10px;
    transition: 0.25s ease-in-out color;
    display: block;
  }
  .nav .themeChange {
    background: white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--boxColor);
    width: 45px;
    height: 45px;
    border: 0.5px solid var(--brand-color);
    cursor: pointer;
    margin-top: 1em;
  }
}
