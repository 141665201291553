.preloader_full {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  z-index: 99999;
  background: var(--bodyColor);
  width: 100%;
  position: fixed;
  top: 0;
}
.preloader_full.hideLoader svg {
  animation: hideLoader 1.5s 1000ms ease-in-out forwards;
  /* transform: translate(); */
  /* opacity: 0;
  height: 0;
  visibility: hidden; */
}
.preloader_full.hideLoader {
  /* animation: hideLoader 1.5s 2500ms ease-in-out forwards; */
  /* opacity: 0; */
  background: transparent;
  visibility: hidden;
  transition: background 1000ms, visibility 00ms 2500ms;
}
.App {
  /* visibility: hidden; */
  display: none;
}
.App.show_app {
  display: block;
  /* visibility: visible; */
}
.preloader_full .cir {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  position: relative;
  /* background: red; */
  border-radius: 100%;
  /* border: #1a1a1a solid 0.3em;
  border-left: var(--brand-color) solid 0.3em; */
}
.preloader_full .cir > div.rot {
  /* animation: infinite linear 1s rotate reverse; */
  width: 100%;
  height: 100%;
  /* background: var(--brand-color); */
  background: conic-gradient(#1fa84f 35%, #1a1a1a 35%);
  border-radius: 100%;
  position: absolute;
  z-index: -1;
  animation: infinite linear 1s rotate;
}
.preloader_full .cir > div.loadingtxt {
  width: calc(100% - 15px);
  height: calc(100% - 15px);
  font-size: 1.3em;
  text-transform: uppercase;
  background: red;
  border-radius: 100%;
  background: var(--bodyColor);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #1fa84f;
  background: radial-gradient(var(--bodyColor), #121212 80%);
}
.preloader_full .cir > div.loadingtxt > div {
  animation: infinite ease-in-out 1s opacityChange alternate;
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes opacityChange {
  from {
    /* transform: rotate(0);
     */
    opacity: 0.3;
  }
  to {
    opacity: 0.7;
    /* transform: rotate(360deg); */
  }
}

.smiley {
  width: 8em;
  height: 8em;
}

.smiley__eye1,
.smiley__eye2,
.smiley__mouth1,
.smiley__mouth2 {
  animation: eye1 3s ease-in-out infinite;
}
.smiley__eye1,
.smiley__eye2 {
  transform-origin: 64px 64px;
  stroke: #1fa84fde;
}
.smiley__eye2 {
  animation-name: eye2;
}
.smiley__mouth1 {
  animation-name: mouth1;
}
.smiley__mouth2 {
  animation-name: mouth2;
  visibility: hidden;
}
.stop_loadingPre {
  animation: hideLoader 1.5s ease-in-out infinite;
}
@keyframes hideLoader {
  0% {
    opacity: 1;
    height: 100%;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    /* height: 0; */
    transform: translateY(-50vh);
  }
  /* 100% {
  } */
}

/* Animations */
@keyframes eye1 {
  from {
    transform: rotate(-260deg) translate(0, -56px);
  }
  50%,
  60% {
    animation-timing-function: cubic-bezier(0.17, 0, 0.58, 1);
    transform: rotate(-40deg) translate(0, -56px) scale(1);
  }
  to {
    transform: rotate(225deg) translate(0, -56px) scale(0.35);
  }
}
@keyframes eye2 {
  from {
    transform: rotate(-260deg) translate(0, -56px);
  }
  50% {
    transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1);
  }
  52.5% {
    transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1, 0);
  }
  55%,
  70% {
    animation-timing-function: cubic-bezier(0, 0, 0.28, 1);
    transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1);
  }
  to {
    transform: rotate(150deg) translate(0, -56px) scale(0.4);
  }
}
@keyframes eyeBlink {
  from,
  25%,
  75%,
  to {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0);
  }
}
@keyframes mouth1 {
  from {
    animation-timing-function: ease-in;
    stroke-dasharray: 0 351.86;
    stroke-dashoffset: 0;
  }
  25% {
    animation-timing-function: ease-out;
    stroke-dasharray: 175.93 351.86;
    stroke-dashoffset: 0;
  }
  50% {
    animation-timing-function: steps(1, start);
    stroke-dasharray: 175.93 351.86;
    stroke-dashoffset: -175.93;
    visibility: visible;
  }
  75%,
  to {
    visibility: hidden;
  }
}
@keyframes mouth2 {
  from {
    animation-timing-function: steps(1, end);
    visibility: hidden;
  }
  50% {
    animation-timing-function: ease-in-out;
    visibility: visible;
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -351.86;
  }
}
