*,
::after,
::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* transition: 0.5s ease-in-out; */
  font-family: headFont;
}
*::selection {
  background: var(--brand-color);
  color: var(--bodyColor);
}

ul {
  list-style-type: none;
}

:root {
  --bodyColor: #f8f6f4;
  --boxColor: #eff0ef;
  --dark-brand-color: #202020;
  --brand-color: #1fa84f;
  --error-color: #ff4444;
  --primaryColor: #404742;
  --title-2-color: #292836;
  --secendaryColor: #5e5e5e;
  --main-margin: hsl(223, 90%, 50%);
  --main-margin: 5em;
}
[data-theme="dark"] {
  --bodyColor: #202020;
  --boxColor: #202020;
  --secendaryColor: #f8f6f4;
  --bodyColor: #151515;
  --boxColor: #1e1e1e;
  --primaryColor: #e4e6e4;
  --brand-color: #1fa84fde;
  --secendaryColor: #bcbcbc;
  --title-2-color: #ebebeb;
}

.home-color-Light {
  background: var(--bodyColor);
}
.home-color-Dark {
  background: var(--dark-brand-color);
}
body {
  background: var(--bodyColor);
  transition: 0.5s ease-in-out;
  height: 100vh;
  overflow-x: hidden;
}
body {
  overflow-x: hidden;
  width: 100%;
}

@font-face {
  font-family: mainFont;
  src: url("./fonts/MPLUSRounded1c-Regular.ttf");
}
@font-face {
  font-family: headFont;
  src: url("./fonts/Outfit-VariableFont_wght.ttf");
}
#tsparticles {
  position: absolute;
  z-index: -900;
}
.unique-text {
  color: var(--brand-color);
}

p {
  color: var(--secendaryColor);
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
a {
  text-decoration: none;
}
.btn {
  background: #eff0ef;
  padding: 8px 25px;
  border-radius: 80px;
  display: block;
  width: max-content;
  position: relative;
  overflow: hidden;
  border: 1px #1fa84f solid;
  outline: none;
  cursor: pointer;
  text-transform: capitalize;
}
.btn p {
  display: block !important;
  z-index: 2 !important;
  position: relative !important;
  font-family: "headFont" !important;
  font-weight: bold;
  font-size: 20px !important;
  margin: 0;
  color: #eff0ef;
  text-transform: capitalize;
  transition: color 0.5s 0.7s;
}

.btn::after {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  background: rgba(31, 168, 79);
  left: 0;
  top: 0;
  z-index: 1;

  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(10deg);
  transition: top 0.7s;
}
.btn::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  background: rgba(31, 168, 79);
  left: 0;
  top: 0;
  z-index: 1;
  /* transform: rotate(45deg); */
  top: 150%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(10deg);
  transition: 0.7s;
}
.btn:hover::after {
  top: -140%;
}
.btn:hover::before {
  top: 240%;
}
.btn:hover p {
  color: var(--primaryColor);
  /* color: ; */
}
.btn:hover {
  transition: border-color 0.5s 0.7s;
  border-color: #eff0ef;
}

[data-theme="dark"] .btn p {
  color: #151515;
}
[data-theme="dark"] .btn {
  background: #1e1e1e;
}
[data-theme="dark"] .btn:hover {
  border-color: #1e1e1e;
}
[data-theme="dark"] .btn:hover p {
  color: #e4e6e4;
  /* color: ; */
}
.pretitle {
  text-transform: uppercase !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: var(--brand-color) !important;
}
.title {
  color: var(--primaryColor) !important;
  font-size: 45px !important;
  font-weight: bold !important;
  position: relative !important;
  text-transform: capitalize !important;
}
.title-2 {
  color: var(--title-2-color) !important;
  font-size: 26px !important;
  font-weight: bold !important ;
  position: relative !important;
  text-transform: capitalize !important;
}
@media (min-width: 992px) {
  .post-title {
    width: 58.33333333%;
  }
}

.box {
  background-color: var(--boxColor);
  padding: 20px;
  border-radius: 5px;
  position: relative;
  height: 100%;
  z-index: 1;
  transition: color 300ms ease-in-out;
  overflow: hidden;
}
.box.box-hover:hover,
.box.box-hover:focus,
.box.box-hover:hover a i,
.box.box-hover:hover .box-icon i,
.box.box-hover:hover > * {
  color: #f9f9f9;
}
.box.box-hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: var(--brand-color);
  transition: transform 400ms ease-in-out;
  transform: scaleX(0);
  transform-origin: left;
  color: #f3f3f3;
  border-radius: var(--border-radius);
}
.box.box-hover:hover:before,
.box.box-hover:focus:before {
  transform: scaleX(1);
}
.nav-link {
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: var(--secendaryColor);
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  cursor: pointer;
  /* font-family: mainFont; */
}
.nav-link:focus,
.nav-link:hover {
  color: var(--brand-color);
}
@media (max-width: 750px) {
  :root {
    --main-margin: 3.5em;
  }
  p {
    font-size: 14px;
  }
  .title {
    color: var(--primaryColor) !important;
    font-size: 40px !important;
    font-weight: bold !important;
    position: relative !important;
    text-transform: capitalize !important;
  }
}
@media (max-width: 600px) {
  :root {
    --main-margin: 3em;
    font-size: 13px;
  }
}
main.left-right {
  display: flex;
  padding: 0 var(--main-margin);
  padding-top: 100px;
  justify-content: space-between;
}

main.left-right .ltl-home h1 {
  font-size: 55px;
  color: var(--primaryColor);
}

main.left-right .rht-home img {
  width: 100%;
}

main.left-right .rht-home {
  width: 60%;
  /* padding: 0 2em; */
  padding-left: 2em;
}
main.left-right .ltl-home {
  padding-right: 3em;
  width: 40%;
}

@media (max-width: 1050px) {
  main.left-right .rht-home {
    width: 60%;
    /* padding: 0 2em; */
    padding-left: 2em;
  }
  main.left-right .ltl-home {
    padding-right: 3em;
    width: 40%;
  }
  main.left-right .ltl-home h1 {
    font-size: 45px !important;
    color: var(--primaryColor);
  }
}
@media (max-width: 978px) {
  main.left-right {
    display: flex;
    padding: 0 var(--main-margin);
    padding-top: 100px;
    flex-direction: column;
  }
  main.left-right .rht-home {
    width: 100%;
    /* padding: 0 2em; */
    padding-left: 2em;
  }
  main.left-right .ltl-home {
    padding-right: 3em;
    width: 100%;
    /* height: calc(100vh - 50px + 16px); */
  }
  main.left-right .ltl-home h1 {
    font-size: 55px;
    color: var(--primaryColor);
    margin-bottom: 0.5em;
  }
}
@media (max-width: 600px) {
  main.left-right .ltl-home h1 {
    font-size: 45px;
    color: var(--primaryColor);
    margin-bottom: 0.5em;
  }
}
.testBlock {
  display: block;
}
.socials {
  display: flex;
}
.socials .sos {
  background: var(--brand-color);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  border-radius: 5px;
  cursor: pointer;
}
.socials .sos svg {
  width: 18px;
  height: 18px;
  fill: var(--bodyColor);
}
.socials .sos svg * {
  fill: var(--bodyColor);
}
.titleNotSeen {
  transform: translate3d(0%, 100%, 0);
  opacity: 0;
  transition: opacity 1.5s ease-in-out, transform 0.7s ease-in-out;
}
.titleInView {
  transform: translate3d(0%, -0%, 0) !important;
  opacity: 1 !important;
}
#progress {
  position: fixed;
  bottom: 20px;
  right: 10px;
  height: 70px;
  width: 70px;
  display: none;
  place-items: center;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 800;
}
#progress #progress-value {
  display: block;
  height: calc(100% - 15px);
  width: calc(100% - 15px);
  border-radius: 50%;
  display: grid;
  background-color: var(--bodyColor);
  place-items: center;
  font-size: 35px;
  color: var(--brand-color);
}
#progress #progress-value svg {
  width: calc(100% - 15px);
  /* stroke: var(--brand-color); */
}
#progress #progress-value svg path {
  /* width: calc(100% - 15px); */
  stroke: var(--brand-color);
}
