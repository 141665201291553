.contactUs {
  margin: 6em var(--main-margin);
}
.contactUs .map {
  border: none;
  width: 100%;
  height: 400px;
  margin-top: 5rem;
  border-radius: 5px;
  background: var(--boxColor);
}
.contactUs .left-right {
  padding: 0;
}
.contactUs .contactInfo .info {
  display: flex;
  align-items: center;
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.contactUs .contactInfo .info img {
  width: 30px;
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
  margin-bottom: 0.5rem;
}
.contactUs .contactInfo .info h5 {
  color: var(--secendaryColor);
  font-size: 20px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}
.contactUs .left-right .rht-home form input,
.contactUs .left-right .rht-home form textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  width: 100%;
  padding: 15px;
  outline: none;
  border: none;
  box-shadow: none;
  background-color: var(--boxColor);
  border-radius: 5px;
  color: var(--title-2-color);
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  resize: none;
}
.contactUs .left-right .rht-home form > div {
  width: 100%;
}
.contactUs .left-right .rht-home form > div:nth-child(1),
.contactUs .left-right .rht-home form > div:nth-child(2) {
  width: calc(50% - 0.5em);
}
.contactUs .left-right .rht-home form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 700px) {
  .contactUs .left-right .rht-home form > div:nth-child(1),
  .contactUs .left-right .rht-home form > div:nth-child(2) {
    width: 100%;
  }
}
@media (max-width: 978px) {
  main.left-right .rht-home {
    width: 100%;
    padding: 0;
    /* padding-left: 2em; */
  }
  main.left-right .ltl-home {
    padding: 0;
    width: 100%;
    /* height: calc(100vh - 50px + 16px); */
  }
}
.btn.loading {
  cursor: not-allowed;
  opacity: 0.7;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}
.contactUs .inputToLeft {
  transform: translate3d(-100%, 0, 0);
  transition: opacity 1.5s ease-in-out, transform 0.7s ease-in-out;
  opacity: 0;
}
.contactUs .inputToRight {
  transform: translate3d(100%, 0, 0);
  transition: opacity 1.5s ease-in-out, transform 0.7s ease-in-out;
  opacity: 0;
}
.contactUs .inputToRight {
  transform: translate3d(100%, 0, 0);
  transition: opacity 1.5s ease-in-out, transform 0.7s ease-in-out;
  opacity: 0;
}
.contactUs .inputButoonHiden {
  transform: scale(0);
  transition: opacity 1.5s ease-in-out, transform 1.1s ease-in-out;
  opacity: 0;
}
.contactUs .inputButoonVisible {
  transform: scale(1);
  /* transition: opacity 1.5s ease-in-out, transform 0.7s ease-in-out; */
  opacity: 1;
}
.contactUs .mapHidden {
  /* transform: scale(0); */
  transition: opacity 1.5s ease-in-out, transform 1.1s ease-in-out;
  opacity: 0;
}
.contactUs .mapInView {
  /* transform: scale(1); */
  /* transition: opacity 1.5s ease-in-out, transform 0.7s ease-in-out; */
  opacity: 1;
}
